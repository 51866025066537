<div *ngIf="showGlobalSpinner">
    <div class="loading">
        <mat-spinner></mat-spinner>
    </div>
</div>
<div breadcrumbs [option]='breadcrumbOptions'></div>
<div>
    <div class="heading">
        <h3>House & Well-being events</h3>
    </div>
    <!-- Participant Attendance Start -->
    <div class="panel">
        <div id="overlay" *ngIf="showParticipantList">
            <div class="participant-table-overlay">
                <div class="participant-list">

                    <div class="nav-details">

                        <span class="image-overlay"><img src="assets/icons/house-title.svg" alt=""></span>
                        <div class="top-header">
                            House Events &#8226; Well-being
                            <span class="image-overlay close-overlay"><img src="assets/icons/close-drawer.svg" alt=""
                                    (click)="closeDialogConfirm()"></span>
                        </div>
                    </div>
                    <div class="nav-details">
                        <div class="current-event">{{overlayDetails.eventDetailName}} </div>
                        <div class="event-status">{{overlayDetails.currentStatus}}</div>
                    </div>
                </div>

                <div class="participant-list nav-details">
                    <div class="card">
                        <div class="header">EVENT ID</div>
                        <div class="sub-header">{{overlayDetails.activityID}}</div>
                    </div>
                    <div class="card">
                        <div class="header">STATE</div>
                        <div class="sub-header">Active</div>
                    </div>
                    <div class="card">
                        <div class="nav-details">
                            <span class="p-t-0 p-b-0 nav-details-image-date"><img src="assets/icons/date-table.svg"
                                    alt=""></span>
                            <div>
                                <div class="sub-header">{{overlayDetails.startDate}}</div>
                                <div class="sub-header-day-count">{{overlayDetails.endDate}}</div>
                            </div>
                        </div>

                    </div>
                    <div class="card">
                        <div class="nav-details">
                            <span class="nav-details-image-clock"><img src="assets/icons/time-table.svg" alt=""></span>
                            <div>
                                <div class="sub-header">{{overlayDetails.startTime}} -</div>
                                <div cclass="sub-header">{{overlayDetails.endTime}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="nav-details">
                            <span class="p-t-0 p-b-0 nav-details-image-location"><img
                                    src="assets/icons/location-table.svg" alt=""></span>
                            <div>
                                <div class="sub-header-location">{{overlayDetails.functionRoomName}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="card" style="border-right: 1px">
                        <div class="card-last">
                            <div class="header">MAX CAP.</div>
                            <div class="next-to">
                                <div class="sub-header"> {{houseEventParticipants.length}}</div>
                                <div class="sub-header-day-count">(at {{overlayDetails.capacity}})</div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="participant-list">
                    <h1>Event Attendance</h1>
                </div>

                <div class="participant-list-new">

                    <p>Participants ({{houseEventParticipants.length}})</p>
                    <div class="upload-add-button" *ngIf="!pastEvent">
                        <button class="btn-primary btn-primary-upload-participant" mat-raised-button
                            (click)="toggleuploadFileDiv('showEventAttUpload')"
                            [ngClass]="{'activebutton': showUploadFile}" style="margin-left: auto;">
                            Upload CSV <img src="assets/icons/excel.svg" alt="" ml10>
                        </button>
                        <div *ngIf="isValidCapacity()">
                            <button matTooltip="Add Participant" matTooltipPosition="above" mat-icon-button color="blue"
                                (click)="showAddParticipants()">
                                <mat-icon class="btn-primary-participant">add_circle_outline</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <!-- Excel upload and browser -->
                <div class="participant-excel-div file-content-box" *ngIf="showEventAttUpload">
                    <div class="content m-t-md m-b-md p-l-md p-r-md position-relative">
                        <div class="w-95">
                            <mat-form-field>
                                <div>
                                    <mat-toolbar class="p-l-md p-r-md ">
                                        <!-- Display files names -->
                                        <input matInput [(ngModel)]="fileName" readonly name="name" />
                                        <!-- Browse Button -->
                                        <button mat-raised-button color="primary">Browse File</button>
                                    </mat-toolbar>

                                    <!-- Fetch selected filed on change -->
                                    <input type="file" #fileInput id="uploadFile" (change)="uploadFileEvt($event)"
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        name="uploadFile" />
                                </div>
                            </mat-form-field>
                            <div class="file-message text-gray-light">Note: Upload only csv file upto 50 MB.
                            </div>

                            <div *ngIf="fileNameMissMatch" class="file-message text-red p-t-sm"> {{ invalidFileMessage
                                }}</div>
                            <div *ngIf="fileError" class="file-message text-red">Please select valid file</div>
                            <ng-container *ngIf="invalidParticipants?.length">
                                <div class="file-message text-red content m-t-lg p-t-sm">The following participants were
                                    not imported
                                    due to errors.</div>
                                <div *ngFor="let invalidEvent of invalidParticipants" class="p-l-md p-t-sm">
                                    <p class="file-message text-red">&bull; {{ invalidEvent?.errorRecordNumber }}, {{
                                        invalidEvent?.invalidFields }}</p>
                                </div>
                            </ng-container>
                        </div>
                        <div class="button-box position-absolute top-25 right-0">
                            <button matTooltip="Cancel" matTooltipPosition="above" mat-icon-button color="warn"
                                (click)="toggleuploadFileDiv('showEventAttUpload')">
                                <mat-icon>close</mat-icon>
                            </button>
                            <button matTooltip="Upload" matTooltipPosition="above" mat-icon-button class="text-green"
                                (click)="uploadFile('showEventAttUpload')" [disabled]="disableUploadCta">
                                <mat-icon>done</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="delete-banner" *ngIf="selectedRows.size > 0">
                    {{ selectedRows.size }} Participants Selected |
                    <button matTooltip=" Delete Participants" matTooltipPosition="above" mat-icon-button color="white"
                        (click)="deleteSelected($event)">
                        <mat-icon style="margin-bottom: 2px;">delete</mat-icon>
                        <u>Delete Participants</u>
                    </button>
                </div>
                <div class="participant-list">
                    <form [formGroup]="addUser">
                        <div class="list-with-check-box">
                            <table class="table">
                                <thead>
                                    <th *ngIf="!pastEvent">
                                        <input type="checkbox" [checked]="selectAll" (change)="toggleAllSelection()" />
                                    </th>
                                    <th>
                                        <span>Name</span>
                                        <img (click)="sortOverlay('name')" [ngClass]="{'active':isOVNameAsc}"
                                            src="assets/icons/sort-by-down.svg" alt="">
                                    </th>
                                    <th>
                                        <span>Email</span>
                                        <img (click)="sortOverlay('email')" [ngClass]="{'active':isOVEmailAsc}"
                                            src="assets/icons/sort-by-down.svg" alt="">
                                    </th>
                                </thead>
                                <tbody>
                                    <tr *ngIf="addParticipant && !pastEvent">
                                        <td>
                                            <input type="checkbox" disabled>
                                        </td>
                                        <td>
                                            <input type="input" disabled>
                                        </td>
                                        <td>
                                            <input class="emailForm" type="input" [(ngModel)]="inputEmail"
                                                formControlName="email" (keyup.enter)="addParticipantToArray()">
                                            <button matTooltip="Cancel" matTooltipPosition="above" mat-icon-button
                                                color="warn" (click)="removeParticipants()">
                                                <mat-icon>close</mat-icon>
                                            </button>
                                            <button matTooltip="Save" matTooltipPosition="above" mat-icon-button
                                                class="text-green" [disabled]="addUser.invalid"
                                                (click)="addParticipantToArray()">
                                                <mat-icon>done</mat-icon>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr *ngFor="let group of houseEventParticipants; let i = index">
                                        <td *ngIf="!pastEvent">
                                            <input type="checkbox" [checked]="selectedRows.has(i)"
                                                (change)="toggleRowSelection(i)">
                                        </td>
                                        <td>
                                            <span class="table-name-head">{{group.name}}</span>
                                        </td>
                                        <td>
                                            {{group.email}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </form>
                </div>
            </div>>
        </div>
        <div class="panel-header">
            <div class="panel-title">
                <span class="p-t-0 p-b-0"><img src="assets/icons/house-title.svg" alt=""></span>
                <p>Events</p>
            </div>
            <div class="displayFlex">
                <div class="m-r-sm">
                    <button class="btn-primary" mat-raised-button (click)="addRow()">Add row</button>
                </div>
                <button class="btn-primary" mat-raised-button (click)="toggleuploadFileDiv('showUploadFile')"
                    [ngClass]="{'activebutton': showUploadFile}">
                    Upload CSV <img src="assets/icons/excel.svg" alt="" ml10>
                </button>
                <!-- <input #csvInput hidden="true" type="file" onclick="this.value=null" (change)="uploadCSV($event)" /> -->
                <div class="button-border inline-block pull-right p-lg m-l-sm" (click)="toggleuploadFileDiv('hideUploadFile')">
                    <app-filter [filterInput]="filterInput" (appliedDateFilter)="appliedDateFilter($event)"
                        (clearFilter)="resetToDefault()"></app-filter>
                </div>
            </div>
        </div>
        <div class="file-content-box" *ngIf="showUploadFile">
            <div class="content m-t-md m-b-md p-l-md p-r-md position-relative">
                <div class="w-95">
                    <mat-form-field>
                        <div>
                            <mat-toolbar class="p-l-md p-r-md ">
                                <!-- Display files names -->
                                <input matInput [(ngModel)]="fileName" readonly name="name" />
                                <!-- Browse Button -->
                                <button mat-raised-button color="primary">Browse File</button>
                            </mat-toolbar>

                            <!-- Fetch selected filed on change -->
                            <input type="file" #fileInput id="uploadFile" (change)="uploadFileEvt($event)"
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                name="uploadFile" />
                        </div>
                    </mat-form-field>
                    <div class="file-message text-gray-light">Note: Upload only csv file upto 50 MB.</div>

                    <div *ngIf="fileNameMissMatch" class="file-message text-red p-t-sm"> {{ invalidFileMessage }}</div>
                    <div *ngIf="fileError" class="file-message text-red">Please select valid file</div>
                    <ng-container *ngIf="invalidEvents?.length">
                        <div class="file-message text-red content m-t-lg p-t-sm">The following events were not imported
                            due to errors.</div>
                        <div *ngFor="let invalidEvent of invalidEvents" class="p-l-md p-t-sm">
                            <p class="file-message text-red">&bull; {{ invalidEvent?.errorRecordNumber }}, {{
                                invalidEvent?.eventDetailName || 'N/A' }}, {{ invalidEvent?.invalidFields }}</p>
                        </div>
                    </ng-container>
                </div>
                <div class="button-box position-absolute top-25 right-0">
                    <button matTooltip="Cancel" matTooltipPosition="above" mat-icon-button color="warn"
                        (click)="toggleuploadFileDiv('showUploadFile')">
                        <mat-icon>close</mat-icon>
                    </button>
                    <button matTooltip="Upload" matTooltipPosition="above" mat-icon-button class="text-green"
                        (click)="uploadFile('showUploadFile')" [disabled]="disableUploadCta">
                        <mat-icon>done</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <p class="no-results-filter" *ngIf="isFilterRes; else displayTable">{{filterRes}}</p>
        <ng-template #displayTable>
            <form *ngIf="houseEvents; else loading" class="panel-body" [formGroup]="eventsTable">
                <div class="events-table">
                    <table class="table participant-table"
                        *ngIf="eventsTable?.get('tableRows')?.controls?.length; else displayErrorMessage">
                        <thead #target class="sticky">
                            <th>
                                <span>Name</span>
                                <img (click)="sort('eventDetailName')" [ngClass]="{'active':isNameAsc}"
                                    src="assets/icons/sort-by-down.svg" alt="">
                            </th>
                            <th>
                                <span>Description</span>
                                <img (click)="sort('eventDetailDescription')" [ngClass]="{'active':isDescriptionAsc}"
                                    src="assets/icons/sort-by-down.svg" alt="">
                            </th>
                            <th>
                                <span>Start-end Date</span>
                                <img (click)="sort('startDate')" [ngClass]="{'active':isDateAsc}"
                                    src="assets/icons/sort-by-down.svg" alt="">
                            </th>
                            <th>
                                <span>Start-end Time</span>
                                <img (click)="sort('startTime')" [ngClass]="{'active':isTimeAsc}"
                                    src="assets/icons/sort-by-down.svg" alt="">
                            </th>
                            <th>
                                <span>Type</span>
                                <img (click)="sort('eventType')" [ngClass]="{'active':isTypeAsc}"
                                    src="assets/icons/sort-by-down.svg" alt="">
                            </th>
                            <th>
                                <span>Registered</span>
                                <img (click)="sort('registered')" [ngClass]="{'active':isRegisteredAsc}"
                                    src="assets/icons/sort-by-down.svg" alt="">
                            </th>
                            <th>
                                <span>Capacity</span>
                                <img (click)="sort('capacity')" [ngClass]="{'active':isCapacityAsc}"
                                    src="assets/icons/sort-by-down.svg" alt="">
                            </th>
                            <th>
                                <span>Room</span>
                                <img (click)="sort('functionRoomName')" [ngClass]="{'active':isRoomAsc}"
                                    src="assets/icons/sort-by-down.svg" alt="">
                            </th>
                            <th>
                                <span>Actions</span>
                            </th>
                        </thead>
                        <tbody>
                            <ng-container formArrayName="tableRows"
                                *ngFor="let group of getFormControls.controls; let i=index">
                                <tr *ngIf="group.get('isEditable').value" [formGroupName]="i">
                                    <td>
                                        <mat-form-field>
                                            <mat-label>Name</mat-label>
                                            <input matInput type="text" formControlName="eventDetailName">
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-form-field>
                                            <mat-label>Description</mat-label>
                                            <input matInput type="text" formControlName="eventDetailDescription">
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-form-field>
                                            <mat-label>Select Start-End date</mat-label>
                                            <mat-date-range-input [rangePicker]="picker" [min]="minDate">
                                                <input matStartDate matInput formControlName="startDate"
                                                    placeholder="Start date" readonly>
                                                <input matEndDate matInput formControlName="endDate"
                                                    placeholder="End date" readonly>
                                            </mat-date-range-input>
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-date-range-picker #picker></mat-date-range-picker>

                                            <mat-error
                                                *ngIf="group.controls.startDate.hasError('matStartDateInvalid')">Invalid
                                                start date</mat-error>
                                            <mat-error
                                                *ngIf="group.controls.endDate.hasError('matEndDateInvalid')">Invalid
                                                end date</mat-error>
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-form-field>
                                            <mat-label for="start-time-date">Select Start-End time</mat-label>
                                            <input matInput name="start-time-date" type="time"
                                                formControlName="startTime" placeholder="Start time">
                                        </mat-form-field>
                                        <mat-form-field class="border-top-0">
                                            <input matInput name="end-time-date" type="time" formControlName="endTime">
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-form-field>
                                            <mat-label>Type</mat-label>
                                            <mat-select formControlName="eventType">
                                                <mat-option value="1">House</mat-option>
                                                <mat-option value="4">Well-being</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-form-field>
                                            <mat-label>Registered</mat-label>
                                            <input matInput type="text" formControlName="registered" disabled>
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-form-field>
                                            <mat-label>Capacity</mat-label>
                                            <input matInput type="text" formControlName="capacity">
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <mat-form-field>
                                            <mat-label>Room</mat-label>
                                            <input matInput type="text" formControlName="functionRoomName">
                                        </mat-form-field>
                                    </td>
                                    <td>
                                        <button matTooltip="Cancel" matTooltipPosition="above" mat-icon-button
                                            color="warn" (click)="cancelRow(i, group)">
                                            <mat-icon>close</mat-icon>
                                        </button>
                                        <button matTooltip="Save" matTooltipPosition="above" mat-icon-button
                                            class="text-green" [disabled]="!eventsTable.valid" (click)="saveRow(group)">
                                            <mat-icon>done</mat-icon>
                                        </button>

                                    </td>
                                </tr>
                                <tr *ngIf="!group.get('isEditable').value">
                                    <td>
                                        <div class="user-profile">
                                            <div>
                                                <span class="table-name-head seeParticipants"
                                                    (click)="openDialog(group)">
                                                    {{group.get('eventDetailName').value}}</span>

                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        {{group.get('readMore').value ? group.get('eventDetailDescription').value :
                                        group.get('eventDetailDescription').value | slice:0:28}}
                                        <a class="seeMore"
                                            *ngIf="group.get('eventDetailDescription').value.length > '28'"
                                            (click)="group.get('readMore').value = !group.get('readMore').value">
                                            {{ group.get('readMore').value ? 'See Less': 'See More' }}
                                        </a>
                                    </td>

                                    <td class="edit">

                                        <span class="m-r-xs"><img src="assets/icons/date-table.svg" alt=""></span>
                                        <span>
                                            {{group.get('startDate').value | dateFormat : 'mmddyy'}} -
                                            {{group.get('endDate').value | dateFormat : 'mmddyy'}}
                                        </span>

                                    </td>
                                    <td class="edit">
                                        <span><img src="assets/icons/time-table.svg" alt=""></span>

                                        <span>{{group.get('startDate').value | timeFormat}} - </span><br>
                                        <span class="m-l-lg">{{group.get('endDate').value | timeFormat}}</span>

                                    </td>

                                    <td>
                                        {{group.get('eventType').value === '4' ? 'Well-being' : 'House'}}
                                    </td>
                                    <td>
                                        {{group.get('registered').value}}
                                    </td>
                                    <td>
                                        {{group.get('capacity').value}}
                                    </td>
                                    <td>
                                        {{group.get('functionRoomName').value}}
                                    </td>
                                    <td>
                                        <button matTooltip="Edit" matTooltipPosition="above" mat-icon-button
                                            color="primary" (click)="editRow(group)"
                                            [disabled]="disableDeleteEditButons[i]">
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                        <button matTooltip="Delete" matTooltipPosition="above" mat-icon-button
                                            color="warn" (click)="deleteRow(i, group)"
                                            [disabled]="disableDeleteEditButons[i]">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                        <button matTooltip="Copy" matTooltipPosition="above" mat-icon-button
                                            class="text-purple" (click)="copyRow(group, i)">
                                            <mat-icon>content_copy</mat-icon>
                                        </button>


                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </form>
        </ng-template>
    </div>
    <!-- Participant Attendance End -->

</div>
<ng-template #displayErrorMessage>
    <p class="background-white m-t-xs">
        <span class="m-l-lg">
            <error-message [errorMessage]="errorMessage"></error-message>
        </span>
    </p>
</ng-template>

<ng-template #loading>
    <div class="loading" *ngIf="!showLoading">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>